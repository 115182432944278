import React from "react";
import {
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import Iframe from "react-iframe";

import { FancyHr } from "../FancyHr";

import HeroJoinSupport from "../../images/hero-join-support.jpg";

const JoinSupport = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const rows = {
    individual: {
      title: "Individual / Family Support",
      pricing: [
        {
          type: "Individual Membership",
          price: 15,
        },
        {
          type: "Family Membership ",
          price: 25,
        },
        {
          type: "Patron Membership",
          price: 50,
        },
        {
          type: "Contributing Membership",
          price: 100,
        },
      ],
    },
    business: {
      title: "Business / Organization Support",
      pricing: [
        {
          type: "Bronze Level Sponsor",
          price: 250,
        },
        {
          type: "Silver Level Sponsor",
          price: 500,
        },
        {
          type: "Gold Level Sponsor",
          price: 750,
        },
        {
          type: "Platinum Level Sponsor",
          price: 1000,
        },
      ],
    },
  };

  return (
    <>
      <Box
        component="img"
        src={HeroJoinSupport}
        alt="image of racc"
        sx={{ width: `100%` }}
      />
      <Box py={isSmall ? 2 : 13} px={isSmall ? 2 : "initial"}>
        <Grid container>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="center">
              <Typography variant="h4" align="center">
                Join & Support
              </Typography>
            </Box>
            <FancyHr center />

            <Typography paragraph align="center">
              We invite you to become a part of this dynamic organization.
              Through your annual membership or sponsorship, the needs of the
              larger community can be served. Together we can fulfill our motto:
              “Unity: For the Love of All People”.z
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </Box>
      <Box px={isSmall ? 2 : "initial"}>
        <Grid container>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={8}>
            <Grid container>
              <Grid item xs={12} md={5}>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            {rows.individual.title}
                          </Typography>
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.individual.pricing.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            {row.type}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {`$${row.price}`}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box mb={4} />
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            {rows.business.title}
                          </Typography>
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.business.pricing.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            {row.type}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {`$${row.price}`}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box mb={4} />
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={5}>
                <Box>
                  <Box display="flex" justifyContent={"flex-start"}>
                    <Typography variant="h6" align={"left"}>
                      Hours
                    </Typography>
                  </Box>

                  <Typography variant="body1" align="left">
                    Monday thru Thursday 11:00 a.m. - 6:00 p.m.
                  </Typography>
                  <Typography paragraph align="left">
                    Friday 11:00 a.m. - 4:00 p.m.
                  </Typography>
                  <Typography align="left">Saturday</Typography>
                  <Typography align="left">
                    Special tours by appointment
                  </Typography>
                  <Typography paragraph align="left">
                    Phone: (913) 682-8772
                  </Typography>
                </Box>
                <Box>
                  <Box display="flex" justifyContent={"flex-start"}>
                    <Typography variant="h6" align={"left"}>
                      Contact
                    </Typography>
                  </Box>

                  <Typography variant="body1" align="left">
                    Edna Wagner, Executive Director
                  </Typography>
                  <Typography paragraph align="left">
                    Phyllis Bass, Director Emeritus
                  </Typography>
                  <Typography paragraph align="left">
                    412 Kiowa Street Leavenworth, KS 66048
                  </Typography>
                  <Typography align="left">
                    For information call the Executive Director at:
                  </Typography>
                  <Typography paragraph align="left">
                    Phone: (913) 682-8772
                  </Typography>
                </Box>
                {/* <Box>
                  <Box display="flex" justifyContent={"flex-start"}>
                    <Typography variant="h6" align={"left"}>
                      Donate
                    </Typography>
                  </Box>
                  <Box>
                    <Iframe
                      url="https://www-jazzbytheriverleavenworth-com.filesusr.com/html/daa676_134a6b7d1a3b2007bb376aa99b97fe3d.html"
                      id="racc-donate"
                      frameBorder="0"
                      target="_blank"
                    />
                  </Box>
                </Box> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}></Grid>
        </Grid>
      </Box>
    </>
  );
};

export default JoinSupport;
